<template>
  <viewcard--c
    title="Clonar Gravação"
    :btsave="null"
    :btdelete="null"
    :btback="{}"
    :busy="!loading"
  >
    <!-- MODAL DE OCORRENCIAS (NOVA OCORRENCIA) -->
    <b-modal
      ref="modal-add-ocorrencias"
      title="Adicionar Ocorrência"
      hide-footer
      centered
      no-close-on-backdrop
      v-model="isOpenModal"
    >
      <div class="row">
        <div class="col-12">
          <occurence-custom
            @submitted="onOccurrenceSubmit"
            :recordingMedia="recordingID"
          />
        </div>
      </div>
    </b-modal>
    <!-- MODAL DE PESQUISAR PROFESSOR (PROFESSOR ATUAL INATIVADO) -->
    <b-modal
      ref="modal-change-teacher"
      v-model="isOpenModalTeacher"
      cancel-variant="outline-secondary"
      ok-title="Clonar e seguir para gravação"
      cancel-title="Fechar"
      centered
      title="O professor desta gravação foi desativado!"
      ok-variant="info btn-icon"
      @ok="cloneWithOtherTeacher"
      no-close-on-backdrop
    >
      <b-form>
        <b-form-group label="Selecione um novo professor">
          <v-select
            v-model="teacherRecording"
            :options="otherTeachers"
            :clearable="false"
            autocomplete="off"
            @search="
              (res) => {
                searchOtherTeacher(res);
              }
            "
            :loading="teacherLoading"
          >
            <template v-slot:option="option">
              <h6 class="text-capitalize m-0">{{ option.label }}</h6>
              <small v-if="option.email">
                {{ option.email }}
              </small>
            </template>
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                Pesquisando... <em>{{ search }}</em>
              </template>
              <em style="opacity: 0.5" v-else>Digite o Nome</em>
            </template>
          </v-select>
        </b-form-group>
      </b-form>
    </b-modal>
    <hr class="p-0 m-0 mb-1" />
    <RecordingInfo @mounted="loadingInfo = true" />
    <hr class="p-0 m-0 mb-1 mt-1" ref="container" />
    <RecordingView
      @mounted="loadingView = true"
      :isClone="true"
      @clone="cloneItems"
    />
  </viewcard--c>
</template>
<script>
import { BModal } from "bootstrap-vue";
import RecordingInfo from "./RecordingInfo.vue";
import RecordingView from "./RecordingView.vue";
import recordingOccurence from "../recording/upload-teacher/components/Occurrence.vue";
import _teacherService from "@/services/teachers-service";
import _recordingMediaService from "@/services/audiovisual/recording-media";

export default {
  components: {
    RecordingInfo,
    RecordingView,
    BModal,
    "occurence-custom": recordingOccurence,
  },
  computed: {
    loading() {
      return this.loadingInfo && this.loadingView;
    },
    recordingID() {
      return this.$route.params.id ? Number.parseInt(this.$route.params.id) : 0;
    },
  },
  data() {
    return {
      loadingInfo: false,
      loadingView: false,
      isOpenModal: false,
      isOpenModalTeacher: false,
      payloadClone: null,
      payloadOcurrence: null,
      teacherRecordingSelected: { label: null, value: null },
      teacherRecording: { label: null, value: null },
      teachers: [],
      otherTeachers: [],
      teacherLoading: false,
      payloadWithOccurrence: null,
    };
  },
  methods: {
    cloneItems(payload) {
      if (payload?.mediafiles?.length === 0) {
        this.$utils.toastError(
          "Notificação",
          "Selecione ao menos um material."
        );
        return;
      }
      var hasError = false;
      payload.mediafiles.forEach((item) => {
        if (item.childrenIds.length === 0) {
          this.$utils.toastError(
            "Notificação",
            `Selecione ao menos um assunto do material selecionado (ID: ${item.id}).`
          );
          hasError = true;
        }
      });

      if (hasError) return;

      this.payloadClone = payload;
      this.isOpenModal = true;
    },
    onOccurrenceSubmit(_form) {
      this.payloadOcurrence = _form;
      this.isOpenModal = false;
      this.loadingView = false;
      this.cloneGravacao();
    },
    searchOtherTeacher(_search) {
      if (_search && _search.length >= 3) {
        if (!this.teacherLoading) {
          this.teacherLoading = true;
          setTimeout(() => {
            _teacherService
              .autoComplete(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.otherTeachers = [];
                  this.otherTeachers = this.$utils.populardropdown(
                    res.content,
                    "name",
                    "id",
                    "email"
                  );
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.teacherLoading = false));
          }, 1500);
        }
      }
    },
    cloneGravacao() {
      const payload = {
        data: {
          recording_media_id: this.recordingID,
          occurrence: {
            ...this.payloadOcurrence,
            relation_key: null,
            data_key: "recording_media",
            is_centraltech: false,
          },
          media_files_clone: this.payloadClone.mediafiles.map((item) => {
            return {
              media_file_id: item.id,
              subjects_id: item.childrenIds.map((sub) => {
                return sub;
              }),
            };
          }),
        },
      };

      _recordingMediaService
        .validateRecodingTeacher(payload.data.recording_media_id)
        .then((res) => {
          if (res.valid) {
            this.clone(payload);
          } else {
            this.payloadWithOccurrence = payload;
            this.loadingView = true;
            this.isOpenModalTeacher = true;
          }
        })
        .catch((error) => {
          this.$utils.toastError("Notificação", error);
          this.loadingView = true;
        });
    },
    cloneWithOtherTeacher() {
      this.loadingView = false;
      if (!this.teacherRecording.value) {
        this.$utils.toastError("OPS!!!", "Informe o professor");
      } else {
        let payload = this.payloadWithOccurrence;
        payload.data.teacher_id = this.teacherRecording.value;

        this.clone(payload);
      }
    },
    clone(payload) {
      _recordingMediaService
        .clone(payload)
        .then((res) => {
          this.$utils.toast("Notificação", "Clone realizado com sucesso.");
          this.$router.push({
            path: `/audiovisual/recording/edit/${res.content.id}`,
          });
        })
        .catch((error) => {
          this.$utils.toastError("Notificação", error);
          this.loadingView = true;
        });
    },
  },
};
</script>