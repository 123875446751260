<template>
  <div class="row">
    <div class="col-12">
      <b-form-group label="Entidade">
        <v-select
          v-model="entitySelected"
          @input="getCategories"
          :options="entityList"
          autocomplete="off"
          :clearable="false"
          :disabled="(recordingMedia && recordingMedia > 0) || isNewRecording"
        />
      </b-form-group>
    </div>
    <div
      class="col-12"
      v-if="entitySelected && entitySelected.value && entitySelected.value == 1"
    >
      <b-form-group label="Professor">
        <v-select
          v-model="teacherSelected"
          :options="teachers"
          :clearable="false"
          autocomplete="off"
        >
          <template v-slot:option="option">
            <h6 class="text-capitalize m-0">{{ option.label }}</h6>
            <small v-if="option.email">
              {{ option.email }}
            </small>
          </template>
        </v-select>
      </b-form-group>
    </div>

    <div class="col-12">
      <b-form-group label="Categoria">
        <v-select
          v-model="categorySelected"
          :options="categoryList"
          autocomplete="off"
          :clearable="false"
        />
      </b-form-group>
    </div>
    <div class="col-12">
      <b-form-group label="Descrição">
        <b-form-textarea
          placeholder="Informe descrição"
          v-model="description"
          rows="7"
        />
      </b-form-group>
    </div>
    <div class="col-12" v-if="DIR_FILE_UPLOAD">
      <UploadPDF
        :file="file.doc"
        :patchSave="false"
        :dirS3="DIR_FILE_UPLOAD"
        :mediaFileID="file.id"
        :fileType="fileTypeUpload"
        :limitSizeFile="LIMIT_SIZE_FILE_UPLOAD"
        @initUpload="inUploadPDF = true"
        @uploadFail="inUploadPDF = false"
        @newFile="
          (file) => {
            item.doc = file;
            inUploadPDF = false;
          }
        "
      />
    </div>
    <div class="col-12">
      <hr />
      <b-button variant="gradient-info" @click="addOccurence">
        <span class="align-middle">OK</span>
      </b-button>
    </div>
  </div>
</template>
<script>
import _entityService from "@/services/audiovisual/occurrence-entity-service";
import _categoryService from "@/services/audiovisual/occurrence-category-service";
import UploadPDF from "@/views/apps/audiovisual/recording/upload-teacher/components/material-upload/components/UploadPDF.vue";

export default {
  components: {
    UploadPDF,
  },
  props: {
    teachers: {
      type: Array,
      required: false,
    },
    recordingMedia: {
      type: Number,
      required: false,
    },
    isNewRecording: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      categoryList: [],
      categorySelected: null,
      entityList: [],
      entitySelected: null,
      teacherSelected: null,
      description: "",
      inUploadPDF: false,
      file: {
        doc: {},
      },
      UPLOAD_FILE: process.env.VUE_APP_UPLOAD_FILE_JOB_OCCURRENCE,
      fileTypeUpload: [".png", ".jpg", ".pdf", ".doc", ".docx", ".txt"],
      DIR_FILE_UPLOAD: "",
      LIMIT_SIZE_FILE_UPLOAD: 0,
    };
  },
  created() {
    this.handleUpload();
    this.getEntities();
  },
  methods: {
    handleUpload() {
      if (this.UPLOAD_FILE) {
        let params_upload_file = this.UPLOAD_FILE.split(",");
        if (params_upload_file.length == 2) {
          this.DIR_FILE_UPLOAD = params_upload_file[0];
          this.LIMIT_SIZE_FILE_UPLOAD = parseInt(params_upload_file[1]);
          if (Number.isNaN(this.LIMIT_SIZE_FILE_UPLOAD))
            this.LIMIT_SIZE_FILE_UPLOAD = 0;
        }
      }
    },
    addOccurence() {
      if (!this.categorySelected) {
        this.$utils.toastError("Notificação", "Selecione uma categoria.");
        return;
      }

      if (!this.description) {
        this.$utils.toastError(
          "Notificação",
          "Informa a descrição da ocorrência."
        );
        return;
      }

      if (this.description.length > 500) {
        this.$utils.toastError("Notificação", "Máximo de 500 caracteres.");
        return;
      }
      //PROFESSOR
      if (
        this.entitySelected.value == 1 &&
        (!this.teacherSelected || this.teacherSelected.value <= 0)
      ) {
        this.$utils.toastError(
          "Notificação",
          "Necessário informar o professor."
        );
        return;
      }

      let payload = {
        occurrence_category_id: this.categorySelected.value,
        description: this.description,
        entity: this.entitySelected.label,
        category: this.categorySelected.label,
        teacher_id: this.teacherSelected ? this.teacherSelected.value : null,
        teacher_name: this.teacherSelected ? this.teacherSelected.label : null,
        file_name: this.file.doc.name,
        file_key: this.file.doc.key,
      };
      this.$emit("submitted", payload);
    },
    getCategories(item) {
      this.teacherSelected = null;
      this.categorySelected = null;
      this.categoryList = item.categories.map((m) => {
        return {
          value: m.id,
          label: m.name,
        };
      });
    },
    getEntities() {
      _entityService
        .all()
        .then((res) => {
          this.entityList = res.content.map((m) => {
            return {
              categories: m.occurrenceCategory,
              value: m.id,
              label: m.name,
            };
          });          

          if (this.recordingMedia && this.recordingMedia > 0 || this.isNewRecording) {
            let selecionado = this.isNewRecording ? this.entityList.filter(f => f.value === 8)[0] : this.entityList.filter(f => f.value === 7)[0];            

            this.entitySelected = {
              categories: selecionado,
              value: selecionado.value,
              label: selecionado.label,
            };

            this.getCategories(selecionado);
          }          
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>